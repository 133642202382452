ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.scrollPage {
  width: 100%;
  height: 100%;
  .scrollWrapper {
    position: relative;
    height: 68px;
    overflow: hidden;
    ul {
      position: absolute;
      top: 0;
      left: 0;
      li {
        .adm-ellipsis {
          font-size: 24px;
          height: 68px;
          line-height: 68px;
        }
      }
    }
  }
}

//动画
.anim {
  transition: all 0.5s;
  margin-top: -68px;
}