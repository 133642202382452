.swiper-box {
  width: 100%;
  height: 100%;
  .adm-page-indicator-dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #ffffff!important;
    margin-left: 6px;
  }
  .adm-page-indicator-dot-active{
    width: 18px;
    height: 18px;
    background: #ffffff!important;
  }
}