.goodsDetail{
  display: flex;  
  flex-direction: column;
  height: 100%;  
  .goodsDetail-main {
    flex: 1;
    overflow-y: auto;
    .goodsDetail-swiper {
      width: 100%;
      height: 40%;
    }
    .goodsDetail-goodDesc {
      width: 100%;
      height: auto;
      .goodsDetail-goodName {
        display: inline-block;
        font-size: 32px;
        font-weight: 400;
        color: rgba(29, 27, 40, 1);
        font-family: PingFangSC-Regular;
        line-height: 44px;
        margin: 32px 0 0 32px;
      }
      .goodsDetail-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: end;
      }
      .goodsDetail-bidPrice{
        color: rgba(203, 1, 15, 1);
        font-weight: 500;
        font-size: 48px;
        display: inline-block;
        margin: 16px 0 0 16px;
        .integral {
          font-size: 28px;
          color: rgba(203, 1, 15, 1);
          font-family: PingFangSC-Medium;
        }
      }
      .goodsDetail-exchange {
        font-size: 28px;
        color: rgba(203, 1, 15, 1);
        font-weight: 500;
        margin-right: 34px;
        font-family: PingFangSC-Medium;
      }
      .goodsDetail-specs {
        width: 100%;
        .goodsDetail-specsContent {
           height: 104px;
           border-top: 2px solid #E7E8EB ;
           border-bottom: 2px solid #E7E8EB ;
           margin:22px 32px 0px 32px;
           display: flex;
           color: rgba(29, 27, 40, 1);
           align-items: center;
           font-size: 28px;
           line-height: 40px;
           font-family: PingFangSC-Regular;
        }
      }
      .goodsDetail-sendGoods {
        width: 100%;
        .goodsDetail-sendGoodsContent {
          height: 104px;
          margin:0px 32px 0px 32px;
          display: flex;
          align-items: center;
          font-size: 28px;
          color: rgba(29, 27, 40, 1);
          font-family: PingFangSC-Regular;
          line-height: 40px;
          .goodsDetail-sendGoodInfo {
            color: rgba(147, 149, 153, 1);
          }
       }
      }
      .goodsDetail-goodInfo {
        width: 100%;
        .goodsDetail-goodInfoContent {
          height: 68px;
          margin:0px 32px 0px 32px;
          background: #F2F4F5;
          border-radius: 8px;
          display: flex;
          align-items: center;
          font-size: 28px;
          line-height: 34px;
          .goodsDetail-goodInfoItem {
            font-size: 24px;
            color: rgba(147, 149, 153, 1);
            font-family: PingFangSC-Regular;
            flex:1;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
       }
      }
    }
    .goodsDetail-Detail {
      width: 100%;
      background: #F7F8FA;
      margin-top: 32px;
      .goodsDetail-detailTitle {
        width: 100%;
        height: 104px;
        font-size: 28px;
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #606366;
        line-height: 40px;
        justify-content: center;
        .goodsDetail-detailTitleText {
          margin: 0 12px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: rgba(96, 99, 102, 1);
        }
      }
      .goodsDetail-DetailImg {
        display: flex;
        justify-content: center;
        align-items:  center;
      }
    }
    
  }
  .goodsDetail-footer {
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .goodsDetail-nowExchange{
    display: inline-block;
    margin: 20px 0px 20px 0px;
    font-weight: 600;
    font-size: 28px;
    color: rgba(255, 255, 255, 1);
    font-family: PingFangSC-Semibold;
    line-height: 40px;
  }
}