.confirmOrder {
  width: 100%;
  display: flex;  
  flex-direction: column;
  height: 100%;
  background: #F7F8FA;
  overflow: hidden;
  .confirmOrder-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .confirmOrder-detail {
      width: 96%;
      height: 200px;
      background: #ffffff;
      border-radius: 16px;
      margin-top: 16px;
      .confirmOrder-detailContent {
        width: 91%;
        height: 68%;
        margin: 32px;
        display: flex;
        .confirmOrder-leftImg {
          width: 21%;
          height: 100%;
          background: greenyellow;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .confirmOrder-rightInfo {
          width: 79%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .confirmOrder-goodsName {
            margin-left: 16px;
            font-size: 28px;
            line-height: 40px;
            flex: 1;
            color: rgba(48, 48, 52, 1);
            font-family: PingFangSC-Regular;
          }
          .confirmOrder-goodsConfig {
            font-size: 24px;
            color: rgba(147, 149, 153, 1);
            font-family: PingFangSC-Regular;
            width: 100%;
            line-height: 34px;
            margin-left: 16px;
            display: flex;
            justify-content: space-between;
            align-items: end;
            
          }
        }
      }
    }
    .confirmOrder-orderConfig {
      width: 96%;
      height: 400px;
      background: #ffffff;
      border-radius: 16px;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      .confirmOrder-orderParams {
         padding:32px 32px 32px 32px;
         border-bottom: 1px solid #cccccc;
         div {
          margin-top: 22px;
         font-size: 28px;
          display: flex;
            justify-content: space-between;
            align-items: end;
            .confirmOrder-PriceNum{
              font-family: PingFangSC-Regular;
              font-size: 28px;
              color: #939599;
              line-height: 40px;
            }
         }
      }
      .confirmOrder-money {
        padding: 30px 32px 32px 32px;
        display: flex;
        justify-content: space-between;
        align-items: end;
        font-size: 32px;
        line-height: 44px;
        .confirmOrder-count{ 
          font-family: PingFangSC-Regular;
          color: rgba(203, 1, 15, 1);
        }
      }
    }
    .confirmOrder-notes {
      width: 96%;
      background: #ffffff;
      border-radius: 16px;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      .confirmOrder-notesInfo {
        display: inline-block;
        font-size: 28px;
        margin-top: 32px;
        margin-left: 32px;
        line-height: 40px;
        font-family: PingFangSC-Regular;
        color: rgba(48, 48, 52, 1);
      }
      .confirmOrder-textarea {
        margin-left: 32px;
        margin-top: 16px;
        height: 260px;
        .adm-text-area-element {
          font-size: 24px!important;
        }
      } 
    }
  }
  .confirmOrder-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    .confirmOrder-total {
      flex: 1;
      line-height: 40px;
      .confirmOrder-totalText {
        margin-left: 32px;
        padding: 32px 0;
        .confirmOrder-totalAll {
          font-size: 28px;
           color: rgba(48, 48, 52, 1);
            font-family: PingFangSC-Regular;
        }
        .confirmOrder-totalBriPrice {
          font-size: 32px;
          line-height: 40px;
          font-family: PingFangSC-Regular;
          color: rgba(203, 1, 15, 1);
        }
      }
    }
    .confirmOrder-submitOrderBox{ 
      flex: 1;
      background: red;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
      font-size: 28px;
      font-weight: 600;
      font-family: PingFangSC-Semibold;
      .confirmOrder-submitOrder {
        padding: 32px 0;
      }
    }
  }
}
.loading {
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  position: fixed;
  top: 20%;
  border-radius: 10px;
  height: 80px;
  line-height: 80px;
  left: 30%;
  text-align: center;
  padding: 10px;
}