.my-masonry-grid {
  display: flex;
  // margin-left: -14px; /* 调整列之间的间距 */
  // width: 100%;
  margin: 0 14px 0px 16px;
}

.my-masonry-grid_column:nth-child(n+2) {
  padding-left: 16px; /* 调整列之间的间距 */
}
