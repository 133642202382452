.MallHomePage{
  width: 100%;
  height: 100%;
  background: #F7F8FA;
  display: flex;
  flex-direction: column;
  background-image: url(../../assets/image/bannerBackground2.png);
  background-size: 100% 400px;
  background-repeat: no-repeat;
.MallHomePage-headSculptue {
    margin: 0 4%;
    height: 7%;
    padding-top: 160px;
    font-size: 24px;
    display: flex;
    align-items: center;
    .head-left {
      height: 100%;
      display: flex;
      align-items: center;
      .MallHomePage-AvatarImg {
        width: 64px;
        height: 64px;
      }

      .head-leftName {
        width: 140px;
        height: 100%;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 16px;
        .head-Name {
          width: 100%;
          font-size: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .head-points {
          font-size: 18px;
          margin-top: 8px;
        }
      }
    }

    .head-icon {
      flex: 1;
      display: flex;
      align-items: center;
      .MallHomePage-search {
        width: 100%;
        .adm-search-bar {
        }
        .adm-search-bar-input-box-icon {
          font-size: 32px;
        }
        .adm-input-element {
          height: 72px;
          font-size: 28px;
        }
        .adm-input-clear .antd-mobile-icon {
          font-size: 32px!important;
        }
      }
    }
  }
  .MallHomePage-box {
    flex: 1;
    overflow-y: auto;
    .MallHomePage-nav {
      width: 100%;
      height: auto;
      .MallHomePage-banner {
        margin: 16px 4% 0px 4%;
        height: 30%;
      }
      .MallHomePage-Notice {
        margin: 16px 4% 0px 4%;
        height: 60px;
        background: #ffffff;
        line-height: 60px;
        display: flex;
        .Notice-left {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          .MallHomePageGong {
            width: 60px;
            height: 36px;
          }
        }
        .Notice-right {
          flex: 1;
          padding-left: 10px;
        }
      }
      .MallHomePage-menu {
        margin: 32px 9% 26px 9%;
        height: 12%;
        display: flex;
        align-items: center;
        .MallHomePage-menuItem {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .menuItem-icon {
            width: 64px;
            height: 64px;
          }
          .itemSpan {
            margin-top: 12px;
            font-size: 24px;
          }
        }
      }
      .MallHomePage-type {
        width: 100%;
        height: 96px;
        background: #ffffff;
        margin-top: 32px;
        display: flex;
        align-items: center;
        .MallHomePage-typeItem {
          flex: 1;
          font-size: 30px;
          color: #303034;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .MallHomePage-main{
      border-radius: 32px 32px 0px 0px;
      background: #ffffff;
      padding-bottom: 10px;
    };
  }
}
