.menu-list {
  width: 100%;
  height: 100%;
  display: flex;

  .menuList-box {
    flex: 1;
    overflow-y: auto;
  }
  .adm-empty {
    width: 100%;
    height: 100%;
    .adm-empty-image-container {
      width: 200px;
      height: 200px;
      .adm-empty-image {
        width: 200px;
          height: 200px;
      }
    }
    .adm-empty-description {
      font-size: 24px;
    }
  }
}