.adm-card{
  padding: 0;
  border-radius: 32px 32px 0px 0px;
  .adm-card-body{
    padding: 0!important;
  }
}
.cardContentImg {
  margin-top: 20px;
  .cardContentImg-desc {
    .cardContentImg-shopName{
      margin: 0 16px;
      font-size: 24px;
      font-weight: 400;
      color: #1D1B28;
      font-family: PingFangSC-Regular;
      display: inline-block;
      line-height: 34px;
    }
    .cardContentImg-price {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: end;
      padding-bottom: 22px;
    }
    .cardContentImg-bidPrice{
      color: rgba(203, 1, 15, 1);
      font-family: Avenir-Medium;
      font-weight: 500;
      font-size: 36px;
      display: inline-block;
      margin: 16px 0 0 16px;
      .cardContentImg-integral {
        font-size: 28px;
        color: rgba(203, 1, 15, 1);
        font-weight: 500;
      }
    }
    .cardContentImg-exchange {
      color: rgba(147, 149, 153, 1);
      font-size: 24px;
      font-weight: 400;
      margin-right: 16px;
      font-family: PingFangSC-Regular;
    }
  }
}



