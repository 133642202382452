.MenuType {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  .menu-box {
    width: 100%;
    height: 220px;
    display: flex;
    .menu-item {
      width: 32%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 1%;
      .menu-itemImg {
        width: 80%;
        height: 80%;
      }
      .menu-itemText {
        display: inline-block;
        margin-top: 14px;
        font-size: 24px;
      }
    }
  }
}