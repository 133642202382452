
@font-face {
  font-family: "iconfont"; /* Project id 4210858 */
  src: url('//at.alicdn.com/t/c/font_4210858_ycoe6j2vbyk.woff2?t=1692780534552') format('woff2'),
       url('//at.alicdn.com/t/c/font_4210858_ycoe6j2vbyk.woff?t=1692780534552') format('woff'),
       url('//at.alicdn.com/t/c/font_4210858_ycoe6j2vbyk.ttf?t=1692780534552') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 50px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youjiantou:before {
  content: "\e60a";
  font-size: 40px;
}

.icon-24gl-playCircle:before {
  content: "\ea6e";
  background: #cccccc;
  border-radius: 50px;
}

.icon-bofangzanting:before {
  content: "\e61d";
}

.icon-bofanganniu:before {
  content: "\e602";
  color: aliceblue;
}

.icon-zuojiantou:before {
  content: "\e67c";
  font-size: 40px;
}

.icon-fenlei:before {
  content: "\e61b";
}

.icon-ershouwupin:before {
  content: "\e689";
}

.icon-shouye:before {
  content: "\e698";
}

.icon-a-02_lixiang:before {
  content: "\e60b";
}

.icon-diancan:before {
  content: "\e66e";
}

.icon-zhihuidiancan:before {
  content: "\e625";
}

.icon-sousuo:before {
  content: "\e6a0";
  font-size: 40px;
  font-weight: 600;
}

